import { NextPage } from 'next';

import { AppLayout } from '@apps/web/components/organisms/app-layout/AppLayout';
import { DocTypeMap } from '@apps/web/components/templates/types';
import { FooterType, NavigationType } from '@apps/web/lib/sanity/types';

import { PageContentArea } from '../page/PageContentArea';

export const FrontpageTemplate: NextPage<{
	pageData: DocTypeMap['frontpage'];
	footer: FooterType;
	navigation: NavigationType;
}> = ({ footer, pageData, navigation }) => {

	return (
		<AppLayout navigation={navigation} footer={footer} colorScheme="blue">
			<PageContentArea pageData={pageData} />
		</AppLayout>
	);
};

export default FrontpageTemplate;
