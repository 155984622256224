import { tailwindConfig } from '@canify/theme';
import { useMediaQuery, useTheme } from '@mui/material';

export const useBreakpoints = () => {
	const theme = useTheme();
	const isMobileDown = useMediaQuery(theme.breakpoints.down('sm'));
	const isMobileUp = useMediaQuery(theme.breakpoints.up('sm'));
	const isTabletDown = useMediaQuery(theme.breakpoints.down('md'));
	const isTabletUp = useMediaQuery(theme.breakpoints.up('md'));
	const isDesktopDown = useMediaQuery(theme.breakpoints.down('lg'));
	const isDesktopUp = useMediaQuery(theme.breakpoints.up('lg'));
	const xxsBreakpoint = 320;
	const isBreakpointXXSOrSmaller = useMediaQuery(theme.breakpoints.down(xxsBreakpoint + 1));
	const isSmallScreen = useMediaQuery(`(max-width: ${tailwindConfig.theme.screens.sm})`);
	const isLargeScreen = useMediaQuery(`(min-width: ${tailwindConfig.theme.screens.lg})`);

	return {
		isSmallScreen,
		isLargeScreen,
		isMobileUp,
		isMobileDown,
		isTabletUp,
		isTabletDown,
		isDesktopDown,
		isDesktopUp,
		isBreakpointXXSOrSmaller };
};
